import React from 'react';
import {authRole} from '../shared/constants/AppConst';
import './ris.scss';

export const risConfigs = [
  {
    auth: authRole.user,
    routes: [
      // Главная
      {
        path: '/main/:doc?',
        component: React.lazy(() => import('./mainDashboard')),
      },
      {
        path: '/main-test/:doc?',
        component: React.lazy(() => import('./mainTest')),
      },
      // Статистика
      {
        path: '/mobile-app-stat/',
        component: React.lazy(() => import('./statistics/mobile_app')),
      },
      {
        path: '/terminals-stat/',
        component: React.lazy(() => import('./statistics/terminals')),
      },
      // Дашбоард
      {
        path: '/dashboard/:doc?',
        component: React.lazy(() => import('./dashboard/main')),
      },
      {
        path: '/service/:id',
        component: React.lazy(() => import('./services/detail')),
      },
      {
        path: '/users/:id?',
        component: React.lazy(() => import('./users')),
        auth: authRole.boss,
      },
      {
        path: '/wiki/:id?',
        component: React.lazy(() => import('./wiki')),
      },
      {
        path: '/address',
        component: React.lazy(() => import('./addresses/list')),
      },
      {
        path: '/rubricator',
        component: React.lazy(() => import('./rubricators/list')),
      },
      {
        path: '/profosmotr',
        component: React.lazy(() => import('./profosmotr')),
        auth: authRole.admin,
      },
      {
        path: '/monitors',
        component: React.lazy(() => import('./monitors')),
        auth: authRole.admin,
      },
      {
        path: '/notification',
        component: React.lazy(() => import('./notification')),
        auth: authRole.admin,
      },
      {
        path: '/notification-settings',
        component: React.lazy(() => import('./notification/settings')),
        auth: authRole.admin,
      },
      {
        path: '/notification-history',
        component: React.lazy(() => import('./notification/history')),
        auth: authRole.admin,
      },
      {
        path: '/notification-informer',
        component: React.lazy(() => import('./notification/informer')),
        auth: authRole.admin,
      },
      {
        path: '/notification-sending',
        component: React.lazy(() => import('./notification/sending')),
        auth: authRole.admin,
      },
      {
        path: '/terminals/:terminalUuid/edit-contract',
        component: React.lazy(() => import('./terminals/contract')),
        auth: authRole.admin,
      },
      {
        path: '/terminals/:terminalId/button-add',
        component: React.lazy(() => import('./terminals/edit/ButtonForm')),
        auth: authRole.admin,
      },
      {
        path: '/terminals/:terminalId/button-edit/:id',
        component: React.lazy(() => import('./terminals/edit/ButtonForm')),
        auth: authRole.admin,
      },
      {
        path: '/terminals',
        component: React.lazy(() => import('./terminals')),
        auth: authRole.admin,
      },
      {
        path: '/terminals-edit/:id',
        component: React.lazy(() => import('./terminals/edit')),
        auth: authRole.admin,
      },
      {
        path: '/terminals-edit',
        component: React.lazy(() => import('./terminals/edit')),
        auth: authRole.admin,
      },
      {
        path: '/mobile',
        component: React.lazy(() => import('./mobile')),
        auth: authRole.admin,
      },
      {
        path: '/mobile-edit/:id',
        component: React.lazy(() => import('./mobile/edit-news')),
        auth: authRole.admin,
      },
      {
        path: '/mobile-edit',
        component: React.lazy(() => import('./mobile/edit-news')),
        auth: authRole.admin,
      },
      {
        path: '/play-list-edit/:id',
        component: React.lazy(() => import('./monitors/play_list_edit')),
        auth: authRole.admin,
      },
      {
        path: '/play-list-edit',
        component: React.lazy(() => import('./monitors/play_list_edit')),
        auth: authRole.admin,
      },
      {
        path: '/monitors-edit/:id',
        component: React.lazy(() => import('./monitors/edit')),
        auth: authRole.admin,
      },
      {
        path: '/monitors-edit',
        component: React.lazy(() => import('./monitors/edit')),
        auth: authRole.admin,
      },
      {
        path: '/rubricator-calc',
        component: React.lazy(() => import('./rubricatorsCalculator')),
        auth: authRole.admin,
      },
      {
        path: '/calls',
        component: React.lazy(() => import('./calls/list')),
      },
      {
        path: '/edit-registries/:id?',
        component: React.lazy(() => import('./editRegistries')),
        auth: authRole.boss,
      },
      {
        path: '/patient/:id',
        component: React.lazy(() => import('./patients')),
      },
      {
        path: '/place-templates/:id?',
        component: React.lazy(() => import('./placeTemplates')),
        auth: authRole.boss,
      },
      /*
      {
        path: '/roles/create',
        component: React.lazy(() => import('./roles/detail')),
      },
      {
        path: '/roles/:id?',
        component: React.lazy(() => import('./roles')),
      },
      */
      {
        path: '/notifications/:id?',
        component: React.lazy(() => import('./under-construction')),
      },
      {
        path: '/profile',
        component: React.lazy(() => import('./users/me')),
      },
      {
        path: '/orders/:id?',
        component: React.lazy(() => import('./crm/orders')),
      },
      {
        path: '/registries/:id?',
        component: React.lazy(() => import('./registry')),
      },
      {
        path: '/moderation',
        component: React.lazy(() => import('./moderation/list')),
        auth: authRole.moderator,
      },
      {
        path: '/test',
        component: React.lazy(() => import('./test')),
        auth: authRole.admin,
      },
      {
        path: '/doctor/:id',
        component: React.lazy(() => import('./doctors/detail')),
      },
      {
        path: '/edit-examinations/:id?',
        component: React.lazy(() => import('./editExaminations')),
        auth: authRole.admin,
      },
      {
        path: '/examination-dashboard/:id?',
        component: React.lazy(() => import('./examinations/dashboard')),
        auth: authRole.admin,
      },
      {
        path: '/examination-ticket/:id?',
        component: React.lazy(() => import('./examinations/ticket')),
      },
      {
        path: '/examinations/:id?',
        component: React.lazy(() => import('./examinations')),
      },
      {
        path: '/new-services/:id?',
        component: React.lazy(() => import('./servicesNew')),
      },
      {
        path: '/section/:id',
        component: React.lazy(() => import('./sections/detail')),
      },
    ],
  },
];
